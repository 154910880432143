import { Grid } from '@mui/material';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import {
  FiltersDrawerButton,
  ReviewDrawerFilter,
  SearchField,
} from '@components';
import { MovementStatusEnum } from '@constants';

import { ExportButton } from './components';
import type { MovementBeneficiariesHeaderProps } from './types';

export function MovementBeneficiariesHeader({
  movementInfo,
  beneficiaries,
}: Readonly<MovementBeneficiariesHeaderProps>) {
  const { filterCount } = useDrawerFilter();

  const { reviewLives, totalLives } = movementInfo.summaryLives;

  const showReviewFilter = reviewLives > 0 && reviewLives !== totalLives;

  const { status } = movementInfo.movementDetails;

  const showExportButton =
    status === MovementStatusEnum.FILE_PROCESSED ||
    status === MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW ||
    status === MovementStatusEnum.ERROR;

  const isBeneficiariesListEmpty = beneficiaries.length === 0;

  const disableFilterButton = !filterCount && isBeneficiariesListEmpty;

  return (
    <Grid container spacing={3}>
      <Grid item display="flex" gap={3} flexDirection="row" flex={1}>
        <Grid item xs={12}>
          <SearchField placeholder="Buscar nome ou CPF do beneficiário" />
        </Grid>
      </Grid>

      {showReviewFilter && (
        <Grid item md={2}>
          <FiltersDrawerButton
            subtitle="Selecione como deseja filtrar os detalhes da movimentação"
            title="Filtrar detalhes da movimentação"
            disabled={disableFilterButton}
          >
            <ReviewDrawerFilter />
          </FiltersDrawerButton>
        </Grid>
      )}

      {showExportButton && (
        <Grid item md={2}>
          <ExportButton />
        </Grid>
      )}
    </Grid>
  );
}
