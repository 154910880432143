import { Typography } from '@mui/material';

import { UserRedirectsEnum } from '@services/UserService';

import { ExternalLinksEnum } from '@constants';

import { IncomeReportsContent } from './components';
import type { BillsRedirectProps } from './types';

export const BILLS_REDIRECTS: BillsRedirectProps = {
  invoiceOrderNumber: {
    title: 'Número de pedido da nota fiscal',
    mainButtonText: 'Acessar portal',
    redirectType: UserRedirectsEnum.INVOICE_ORDER_NUMBER,
    content: (
      <Typography variant="text">
        A funcionalidade estará disponível apenas para alguns contratos. Para
        mais informações, entre em contato com o Executivo de Conta.
      </Typography>
    ),
  },
  financialHistoryReport: {
    title: 'Relatório de histórico financeiro',
    mainButtonText: 'Acessar portal',
    mainAction: () => {
      window.open(ExternalLinksEnum.NDI, '_blank');
    },
    content: (
      <Typography variant="text">
        Caso queira ter acesso aos{' '}
        <Typography variant="text" fontWeight={700}>
          Relatórios de histórico financeiro
        </Typography>{' '}
        anteriores ao mês de dezembro de 2024, acesse o portal NDI, clique em
        “Acessar portal” para ser direcionado para uma nova página.
      </Typography>
    ),
  },
  incomeReport: {
    title: 'Informe de rendimentos',
    content: <IncomeReportsContent />,
  },
  monthlyFeePreviewReport: {
    title: 'Relatório de prévia de mensalidade',
    mainButtonText: 'Acessar portal',
    redirectType: UserRedirectsEnum.MONTHLY_FEE_PREVIEW_REPORT,
    content: (
      <Typography variant="text">
        Para acessar{' '}
        <Typography variant="text" fontWeight={700}>
          Relatório de prévia de mensalidade,
        </Typography>{' '}
        clique em “Acessar portal” para ser direcionado para uma nova página.
      </Typography>
    ),
  },
};
