import { UserRedirectsEnum } from '@services/UserService';

import { IAppSubRoute } from 'routes/types';

import { BILLS_REDIRECTS } from '.';

const InvoiceOrderNumberRouter: IAppSubRoute = {
  name: 'Número de pedido da nota fiscal',
  path: UserRedirectsEnum.INVOICE_ORDER_NUMBER,
  isPrivate: true,
  sidebarModalProps: BILLS_REDIRECTS.invoiceOrderNumber,
};

const FinancialHistoryReportRouter: IAppSubRoute = {
  name: 'Relatório de histórico financeiro',
  path: 'financial-history-report',
  isPrivate: true,
  sidebarModalProps: BILLS_REDIRECTS.financialHistoryReport,
};

const IncomeReportRouter: IAppSubRoute = {
  name: 'Informe de rendimentos',
  path: UserRedirectsEnum.INCOME_REPORT,
  isPrivate: true,
  sidebarModalProps: BILLS_REDIRECTS.incomeReport,
};

const MonthlyFeePreviewReport: IAppSubRoute = {
  name: 'Relatório de prévia de mensalidade',
  path: UserRedirectsEnum.MONTHLY_FEE_PREVIEW_REPORT,
  isPrivate: true,
  sidebarModalProps: BILLS_REDIRECTS.monthlyFeePreviewReport,
  optionalRedirect: UserRedirectsEnum.MONTHLY_FEE_PREVIEW_REPORT,
};

export {
  FinancialHistoryReportRouter,
  InvoiceOrderNumberRouter,
  IncomeReportRouter,
  MonthlyFeePreviewReport,
};
