import { MovementStatusEnum } from '@constants';

export enum IntegrationStatusEnum {
  Error = 'Error',
  ErrorNdi = 'ErrorNdi',
  ErrorHapvida = 'ErrorHapvida',
}

interface Affiliates {
  fantasyName: string;
  document: string;
  corporateName: string;
  state: string;
  city: string;
  exclusivity: string;
  commissioning: string;
  createdAt: string;
  lastModifiedAt: string;
  joinedCompaniesFile: any;
  url: string;
  affiliatesData: Affiliates[];
}

interface MovimentationCompanyDetails {
  fantasyName: string;
  document: string;
  corporateName: string;
  state: string;
  city: string;
  exclusivity: string;
  commissioning: string;
  affiliatesData: Affiliates[];
  createdAt: string;
  lastModifiedAt: string;
  joinedCompaniesFile: any;
  url: string;
}

interface MovimentationDetail {
  movementDetails: {
    number: string;
    createdAt: string;
    updatedAt: string;
    status: MovementStatusEnum;
    uploadName: string;
    fileName?: string;
    totalShippingReviews: number;
    integrationStatus: IntegrationStatusEnum;
  };
  summaryLives: {
    totalLives: number;
    processedLives: number;
    reviewLives: number;
    processingLives: number;
    canceledLives: number;
  };
}

interface MovimentationDetailRequest {
  content: MovimentationDetail;
  companyDetail?: MovimentationCompanyDetails;
}

export { MovimentationDetail, MovimentationDetailRequest };
