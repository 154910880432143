import { COLORS } from '@hapvida/hapvida-core-components';

enum PlanTypeEnum {
  HEALTH = 'Health',
  DENTAL = 'Dental',
  HEALTH_DENTAL = 'Health and Dental',
}

type PlanType = Record<PlanTypeEnum, string>;

const PLAN_TYPE_NAME: PlanType = {
  [PlanTypeEnum.HEALTH]: 'Saúde',
  [PlanTypeEnum.DENTAL]: 'Odonto Puro',
  [PlanTypeEnum.HEALTH_DENTAL]: 'Saúde + Odonto',
};

const PLAN_TYPE_COLOR: PlanType = {
  [PlanTypeEnum.HEALTH]: COLORS.PRIMARY.BLUE.MAIN,
  [PlanTypeEnum.DENTAL]: COLORS.PRIMARY.ORANGE['-3'],
  [PlanTypeEnum.HEALTH_DENTAL]: COLORS.SECONDARY.GREEN['-2'],
};

export { PLAN_TYPE_COLOR, PLAN_TYPE_NAME, PlanTypeEnum };
