import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import { COLORS, Icon, Mask } from '@hapvida/hapvida-core-components';

import {
  USER_STATUS_COLOR,
  USER_STATUS_ICON_NAME,
  USER_STATUS_NAME,
  UserStatusEnum,
} from '@constants';
import { UserListColumnNameEnum } from '@flows/private/users/pages/UserList/components/UserListCard/constants';
import { ProfileDetailsButton } from '@components';

import { useReprocessUser } from './useReprocessUser';
import { ReprocessButton } from '../styles';
import type { UseUserListTableRowsProps } from './types';

export function useUserListTableRows({ users }: UseUserListTableRowsProps) {
  const { loadingState, handleReprocessUser } = useReprocessUser();

  const userListTableRows = useMemo(
    () =>
      users?.map(user => {
        const isError = user.status === UserStatusEnum.ERROR;
        const isProcessing = user.status === UserStatusEnum.PROCESSING;

        return {
          id: user.id,
          errorComponent: isError && (
            <Stack
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color={COLORS.PRIMARY.RED['+1']} variant="text">
                Erro ao processar usuário. Tente novamente. Caso o erro
                persista, o usuário será excluído automaticamente em 72 horas
              </Typography>
              <ReprocessButton
                disabled={loadingState}
                onClick={() => handleReprocessUser(user.id)}
                endIcon={<Icon name="refresh" size={16} />}
                variant="contained"
                color="secondary"
                size="small"
              >
                Reprocessar
              </ReprocessButton>
            </Stack>
          ),
          itHasError: isError,
          showEditButton: !isProcessing,
          main: {
            [UserListColumnNameEnum.PERSONAL_DATA]: (
              <Stack>
                <Typography fontWeight={700} variant="subtitle2">
                  {user?.name}
                </Typography>
                {user?.document && (
                  <Typography variant="smallText">
                    {Mask.cpf(user?.document) ?? '-'}
                  </Typography>
                )}
              </Stack>
            ),
            [UserListColumnNameEnum.PROFILE]: (
              <ProfileDetailsButton
                profileId={user.profile?.id}
                profileName={user.profile?.name}
              />
            ),
            [UserListColumnNameEnum.EMAIL]: (
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {user.email}
              </Typography>
            ),
            [UserListColumnNameEnum.STATUS]: (
              <Stack direction="row" alignItems="center" gap={1}>
                <Icon
                  name={USER_STATUS_ICON_NAME[user.status]}
                  color={USER_STATUS_COLOR[user.status]}
                  size={20}
                />
                <Typography
                  fontWeight={600}
                  variant="smallText"
                  color={USER_STATUS_COLOR[user.status]}
                >
                  {user.status ? USER_STATUS_NAME[user.status] : '-'}
                </Typography>
              </Stack>
            ),
          },
          dependents: [],
        };
      }) ?? [],
    [users, loadingState],
  );

  return { loadingState, userListTableRows };
}
