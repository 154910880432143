import { Mask, Pill, Company } from '@hapvida/hapvida-core-components';

import {
  BillStatus,
  BillType,
} from '@services/FinancialService/dtos/BillsListDTO';
import type { Bill } from '@services/FinancialService/dtos/BillsListDTO';
import type { BillListProps } from '@services/FinancialService';

import { BillsListSortNameEnum } from '@flows/private/financial/constants/columns';
import Identifier from '@flows/private/financial/components/Identifier';
import SimpleText from '@flows/private/financial/components/SimpleText';

import type { BillTableRow } from './types';

export function createBillTableRows(billsList: BillListProps): BillTableRow[] {
  function convertBillMonthToCase(billMonth?: string): string {
    if (billMonth) {
      return (
        billMonth.charAt(0).toUpperCase() + billMonth.toLowerCase().slice(1)
      );
    }
    return '-';
  }

  return billsList?.bills?.map((bill: Bill) => {
    const contractNumber = bill.partnerCompany;

    const main = {
      [BillsListSortNameEnum.NUMBER]: <Identifier bill={bill} />,
      [BillsListSortNameEnum.PARTNER_COMPANY]: (
        <SimpleText text={contractNumber} />
      ),
      [BillsListSortNameEnum.DUE_DATE]: (
        <SimpleText text={new Date(bill?.dueDate).toLocaleDateString()} />
      ),
      [BillsListSortNameEnum.MONTH]: (
        <SimpleText text={convertBillMonthToCase(bill?.month)} />
      ),
      [BillsListSortNameEnum.AMOUNT]: (
        <SimpleText
          text={Mask.currency(bill?.amount).toString()}
          fontWeight={700}
        />
      ),
      [BillsListSortNameEnum.TYPE]: BillType[bill?.billType],
      [BillsListSortNameEnum.STATUS]: (
        <Pill
          text={BillStatus[bill.status]?.label}
          color={BillStatus[bill.status]?.color}
          fitContent
        />
      ),
      [BillsListSortNameEnum.HEALTH_OPERATOR]: (
        <Company company={bill.healthOperator} />
      ),
    };

    return {
      id: String(bill.billingNumber),
      main,
    };
  });
}
