import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import MovimentationService from '@services/MovimentationService';

import { base64ToDownloadFile, toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';

export function useExportMovementFile() {
  const { enqueueSnackbar } = useSnackbar();
  const { movementId = '' } = useParams();
  const queryClient = useQueryClient();
  const { selectedCompany } = useAuth();

  const isFetchingMovementFile = useIsFetching({
    queryKey: ['movementFileState'],
  });

  const movementService = useMemo(() => new MovimentationService(), []);

  const companyId = selectedCompany?.id ?? '';

  const handleExportMovementFile = async () => {
    try {
      const movementFileResponse = await queryClient.fetchQuery({
        queryKey: ['movementFileState', companyId, movementId],
        queryFn: () =>
          movementService.getExportedMovementFile({
            movementId,
          }),
      });

      base64ToDownloadFile({
        base64String: movementFileResponse.data,
        fileName: `${movementId}.csv`,
        fileType: 'text/csv',
      });
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  };

  return {
    isExportingMovement: isFetchingMovementFile > 0,
    handleExportMovementFile,
  };
}
