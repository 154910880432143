import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { BillListRouter } from './pages/List/router';
import {
  FinancialHistoryReportRouter,
  InvoiceOrderNumberRouter,
  IncomeReportRouter,
  MonthlyFeePreviewReport,
} from './redirects/router';

export const FinancialGroupRouter: IAppRoute = {
  Page: BillListRouter.Page,
  path: BillListRouter.path,
  name: 'Financeiro',
  isPrivate: true,
  Icon: <Icon name="money" />,
  subRoutes: [
    BillListRouter,
    InvoiceOrderNumberRouter,
    FinancialHistoryReportRouter,
    IncomeReportRouter,
    MonthlyFeePreviewReport,
  ],
};
