import { Button } from '@mui/material';

import { Icon, COLORS } from '@hapvida/hapvida-core-components';

import { CircularProgress } from '@hapvida/hapvida-core-components/src/components/CircularProgress';

import { useExportMovementFile } from './useExportMovementFile';

export function ExportButton() {
  const { isExportingMovement, handleExportMovementFile } =
    useExportMovementFile();

  return (
    <Button
      onClick={handleExportMovementFile}
      disabled={isExportingMovement}
      variant="outlined"
      fullWidth
      endIcon={
        isExportingMovement ? (
          <CircularProgress
            progressColor={COLORS.MONOCHROMATIC.GRAY4}
            size={16}
          />
        ) : (
          <Icon name="file-check" size={20} />
        )
      }
    >
      Exportar
    </Button>
  );
}
